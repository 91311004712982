<template>
  <el-config-provider size="large">
    <div :class="$style.component">
      <router-view></router-view>
      <router-view name="nav"></router-view>
    </div>
  </el-config-provider>
</template>

<style lang="scss" module>
.component {
  background-color: var(--mph-color-primary);
  height: 100vh;
  overflow: auto;
}
</style>
