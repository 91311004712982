import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    components: {
      default: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
      nav: () => import(/* webpackChunkName: "home" */ '../components/NavComponent.vue'),
    },
  },
  {
    path: '/camera',
    name: 'camera',
    component: () => import(/* webpackChunkName: "camera" */ '../views/CameraView.vue'),
  },
  {
    path: '/phone',
    name: 'phone',
    component: () => import(/* webpackChunkName: "phone" */ '../views/PhoneView.vue'),
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "demo" */ '../views/DemoView.vue'),
  },
  {
    path: '/country-files',
    name: 'country-files',
    component: () => import(/* webpackChunkName: "demo" */ '../views/country-files/country-files.component.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
