import { createApp } from 'vue';
import '@/styles/index.scss';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { HelperLink } from 'mph-helper';
import '@/registerServiceWorker';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

export const app = createApp(App);

Object
  .entries(ElementPlusIconsVue)
  .forEach(([key, value]) => {
    app.component(`Icon${key}`, value);
  });

app
  .use(HelperLink)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .mount('#app');
